import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";
import Listing from "../components/listing";
import PostSEO from '../components/post-seo';


import screen2 from "../images/screenshots/perch1.png";

const imageObj = {
  src: screen2,
  width: "300",
  height: "200",
};

const BlogPage = props => (
  <Layout title="Development &nbsp; Blog">
    <PostSEO title="Dune Sea" description="DuneSea Development Blog" image={imageObj} />
    <Container>
      <Listing />
    </Container>
  </Layout>
);

export default BlogPage;
