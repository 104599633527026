import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Row, Col, Media, Image } from "react-bootstrap";

const MedBody = styled(Media.Body)`
  h4 {
    margin-bottom: 0.8rem;
  }
  p {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 0.1rem;
  }
  .post-date {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .post-desc {
    font-size: 0.9rem;
  }

  @media(max-width:767px) {
    h4 {
      font-size: 1.25rem;
    }
    .post-desc {
      display: none;
    }
`;

const PostEntry = ({ node }) => (
  <Media>
    <Image
      src={node.frontmatter.featuredImage.childImageSharp.fixed.src}
      alt="zzz" rounded
      className="mr-3"
      width={120}
      height={120}
    />
    <MedBody>
      <Link to={`/posts${node.frontmatter.slug}`}>
        <h4>{node.frontmatter.title}</h4>
      </Link>
      <p className="post-desc">{node.excerpt}</p>
      <div className="post-date">{node.frontmatter.date}</div>
      <hr />
    </MedBody>
  </Media>
);

const Listing = () => (
  <StaticQuery
    query={LISTING_QUERY}
    render={({ allMarkdownRemark }) =>
      allMarkdownRemark.edges.map(({ node }) => (
        <Row className="justify-content-md-center row-pad" key={node.frontmatter.slug}>
          <Col md={9}>
            <PostEntry node={node} />
          </Col>
        </Row>
      ))
    }
  />
);

const LISTING_QUERY = graphql`
  query BlogPostListing {
    allMarkdownRemark(
      limit: 12
      filter: { frontmatter: { published: {eq: true} }}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            slug
            published
            featuredImage {
              childImageSharp {
                fixed(width: 125, height: 125) {
                  src
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Listing;
